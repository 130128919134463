import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import {getRequest} from "@/api/api";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'
import svgIcon from '@/components/common/svg-icon'
Vue.prototype.getRequest = getRequest;
Vue.component('svgIcon', svgIcon)
Vue.config.productionTip = false
Vue.use(Antd)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
