<template>
  <div class="icon-view">
    <!-- 利用filter: drop-shadow投影出不同颜色的svg，用偏移量把投影替代原本的svg图像 -->
    <img :src="iSrc" :style="checkScreenSize ? `filter: drop-shadow(13px 0px currentColor);` : `filter: drop-shadow(999px 0px currentColor);`">
  </div>
</template>

<script>
  export default {
    name:'SvgIcon',
    data() {
      return{
        checkScreenSize: window.innerWidth < 800,
      }
    },
    computed: {
      getScreenSize() {
        return  window.innerWidth < 800;
      },
    },
    props: {
      iColor: {
        type:String,
        // 默认为主题颜色#5273de
        default:'#5273de',
      },
      iSrc:{
        type:String,
        required:true
      }
    },
    methods: {
      handleResize() {
        this.checkScreenSize = window.innerWidth < 800;
      },
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    }
  }
</script>

<style lang="less" scoped>
.icon-view{
  position: relative;
  height: 100%;
  width: 100%;
  // 隐藏移出框外的源本img样式
  overflow: hidden;
  img{
    position:absolute; 
    top: 0px; 
    // 移走原本的svg
    left: -999px;
    height: 100%;
  }
}
</style>